import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    id: 0,
    email: '',
    name:'',
    eng_name: '',
    image: '',
    whatsapp: '',
    phone: '',
    address: '',
    en_address: '',
    theme: '',
    facebook: null,
    instagram: null,
    twitter: null,
    service_type: '',
    opened_from : '',
    opened_to : '',
    url: '',
    tables: 0,
    plan: '',
}

export const userSlice = createSlice({
    name: 'auth/user',
    initialState,
    reducers: {
        setUser: (_, action) => action.payload,
        userLoggedOut: () => initialState,
    },
})

export const { setUser } = userSlice.actions

export default userSlice.reducer
